.placed_order_container {
    /* margin: 150px; */
}

@media (max-width: 768px) {
    .placed_order_container {
        margin-top: 30px;
    }

    .category_head {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .category_head h3 {
        font-size: 18px;

    }
}