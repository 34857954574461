

@media (max-width: 768px) {
    .elementor-widget-wrap img{
        display: none;
        }
        .elementor-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
}
